import request from "@/utils/request";
import { serverUrl } from "@/utils/request";

export const baseURL = serverUrl;

// 查询仓库列表
export function selectPage(query) {
  return request({
    url: "/inventory/warehouse/selectPage",
    method: "get",
    params: query
  });
}

// 查询仓库列表（排除节点）
export function listWarehouseExcludeChild(warehouseId) {
  return request({
    url: '/inventory/warehouse/list/exclude/' + warehouseId,
    method: 'get'
  })
}

// 查询仓库下拉树结构
export function warehouseTreeselect() {
  return request({
    url: '/inventory/warehouse/treeselect',
    method: 'get'
  })
}

// 新增仓库
export function addWarehouse(data) {
  return request({
    url: "/inventory/warehouse",
    method: "post",
    data: data
  });
}

// 修改仓库
export function updateWarehouse(data) {
  return request({
    url: "/inventory/warehouse",
    method: "put",
    data: data
  });
}

// 删除仓库
export function delWarehouse(id) {
  return request({
    url: "/inventory/warehouse/" + id,
    method: "delete"
  });
}

// 查询仓库详细
export function getWarehouse(query) {
  return request({
    url: "/inventory/warehouse/selectOne",
    method: "get",
    params: query
  });
}

// 修改仓库状态
export function changeWarehourseStatus(data) {
  return request({
    url: "/inventory/warehouse/able",
    method: "put",
    params: data
  });
}

//查询所有启用的仓库(只展示自己负责的仓库)
export function selectByEnableSelf() {
  return request({
    url: "/inventory/warehouse/selectByEnableSelf",
    method: "get"
  });
}

//查询所有启用的仓库(所有的仓库)
export function selectWareByEnable() {
  return request({
    url: "inventory/warehouse/selectByEnable",
    method: "get"
  });
}
